<template>
    <div class="page bg-white recordQueryBox">
        <div class="flex_l_c">
            <div class="flex_l_c srhBox">
                <SelectTree class="selectBox" ref="menuParentTree"
                            :props="defaultProps"
                            :data="classifyList"
                            :value="searchForm.archivesBasicDataId"
                            :clearable="false"
                            :accordion="true"
                            @getValue="(value) => {searchForm.archivesBasicDataId=value}"/>
                <el-input class="el-input__inner2" v-model="searchForm.keyword" clearable maxlength="50"
                          placeholder="请输入关键词进行检索，多个关键词用空格分开（限50字）" @keyup.enter.native="search(1)"></el-input>
                <el-button type="primary" @click="search(1)">检索</el-button>
                <el-button @click="reset()">重置</el-button>
            </div>
            <div class="tips2">对检索结果不满意？试试
                <el-button type="text" @click="advancedSearch()">高级检索</el-button>
            </div>
        </div>
        <div class="tips">本次检索耗时1.43s，共为您找到相关结果约{{total}}个</div>
        <el-button icon="el-icon-star-off" type="primary" size="small"
                   v-show="hasPermission('databaseRetrieval:collect')"
                   @click="collect()">加入收藏
        </el-button>
        <el-button size="small" v-show="hasPermission('databaseRetrieval:export')"
                   @click="exportData()">
            <i class="icon-piliangdaochu iconfont buIcon"/>批量导出
        </el-button>
        <el-button icon="el-icon-download" size="small" v-show="hasPermission('databaseRetrieval:download')"
                   @click="downloadData()">批量下载</el-button>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                height="calc(100vh - 355px)"
                @selection-change="selectionChangeHandle"
                class="table" ref="multipleTable" row-key='id'>
            <el-table-column type="selection" :reserve-selection='true' fixed/>
            <el-table-column prop="archivesBasicDataName" label="档案分类" min-width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-html="scope.row.archivesBasicDataName"></span>
                </template>
            </el-table-column>
            <el-table-column prop="archivesBasicDataType" label="档案类型" min-width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-html="scope.row.archivesBasicDataType"></span>
                </template>
            </el-table-column>
            <el-table-column prop="archivesDataName" label="题名" min-width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-html="scope.row.archivesDataName"></span>
                </template>
            </el-table-column>
            <el-table-column prop="warehousingState" label="状态" min-width="120" show-overflow-tooltip></el-table-column>
            <el-table-column prop="quanzongNumber" label="全宗号" min-width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-html="scope.row.quanzongNumber"></span>
                </template>
            </el-table-column>
            <el-table-column prop="quanzongName" label="全宗名称" min-width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-html="scope.row.quanzongName"></span>
                </template>
            </el-table-column>
            <el-table-column prop="reference" label="档号" min-width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-html="scope.row.reference"></span>
                </template>
            </el-table-column>
            <el-table-column v-for="item in config" :key="item.archivesBasicId"
                             :prop="item.code"
                             :label="item.forName ? item.forName : item.basicName"
                             v-if="item.dataSelect == 0"
                             show-overflow-tooltip min-width="120">
                <template slot="header" slot-scope="scope">
                    <el-tooltip class="item" effect="dark" :content="item.forName ? item.forName : item.basicName" placement="top-start">
                        <span class="omit">{{item.forName ? item.forName : item.basicName}}</span>
                    </el-tooltip>
                </template>
                <template slot-scope="scope">
                    <span v-if="item.archivesBasicTypeCode == 'date'">{{metadataDateFormatValue(item, scope.row.archivesData[item.code])}}</span>
                    <span v-else v-html="scope.row.archivesData[item.code]"></span>
                </template>
            </el-table-column>
            <el-table-column fixed="right" :width="listConfig.length ? '120': ''" label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small"
                               v-show="hasPermission('databaseRetrieval:view')"
                               @click="view(scope.row, 'view')">详情
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="searchForm.current"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="searchForm.size"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <ViewPage ref="viewPage"></ViewPage>
        <AdvancedSearch ref="advancedSearch" @getDataList="getSrhCondition"></AdvancedSearch>
        <ExportData ref="exportData"></ExportData>
    </div>
</template>

<script>
    import SelectTree from '@/components/treeSelect/treeSelect.vue'
    import ViewPage from '../recordation/listForm'
    import AdvancedSearch from '@/components/advancedSearch/advancedSearch'
    import ExportData from '../collect/exportData'

    export default {
        components: {
            SelectTree, ViewPage, AdvancedSearch, ExportData
        },
        data() {
            return {
                defaultProps: {
                    value: 'id',
                    label: 'archivesBasicName',
                    children: 'children',
                },
                classifyList: [],
                searchForm: {
                    archivesBasicDataId: '',
                    keyword: '',
                    size: 10,
                    current: 1,
                    whereList: [],
                    fieldWhereList: [],
                    "fieldList": [],
                    "fieldOrList": [],
                    highlight: true,
                },
                config: [],
                listConfig: [],
                advancedList: [],
                dataList: [],
                dataListSelections: [],
                loading: false,
                total: 0,
            }
        },
        watch: {
            'searchForm.archivesBasicDataId'(val) {
                this.$refs.multipleTable.clearSelection()
                this.searchForm.whereList = []
                this.searchForm.fieldWhereList = []
                this.advancedList = []
                if (val != '1') {
                    this.getConfig()
                } else {
                    this.getAllData()
                }
            }
        },
        mounted() {
            this.getClassifyList()
        },
        methods: {
            // 检索
            search(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                if (type == 0) {
                    this.$refs.multipleTable.clearSelection()
                }
                this.loading = true
                let p = JSON.parse(JSON.stringify(this.searchForm))
                p.archivesBasicDataId = p.archivesBasicDataId == '1' ? null : p.archivesBasicDataId
                this.$axios(this.api.record.queryDatabaseSearchPage, p, 'post').then(res => {
                    if (res.status) {
                        this.dataList = res.data.records
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                    })
                })

            },
            // 全部分类元数据字段
            getAllData() {
                this.$axios(this.api.record.getArchivesBasicByRequired).then((res) => {
                    if (res.status) {
                        this.config = res.data
                        this.search(1)
                    }
                })
            },
            // 获取档案分类元数据字段
            getConfig() {
                let id = this.searchForm.archivesBasicDataId == '1' ? null : this.searchForm.archivesBasicDataId
                this.$axios(this.api.record.basicdataGetById + '/' + id).then(res => {
                    if (res.status) {
                        this.listConfig = []
                        this.config = res.data.archivesBasicDataGroup
                        this.config.map(item => {
                            if (item.dataSelect == 0) {
                                this.listConfig.push(item)
                            }
                        })
                        this.search(1)
                    }
                })
            },
            // 加入收藏
            collect(item) {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let list = []
                if (item) {
                    list = [{
                        archivesBasicDataId: item.archivesBasicDataId,
                        archivesDataId: item.id
                    }];
                } else {
                    list = this.dataListSelections.map(v => {
                        return {
                            archivesBasicDataId: v.archivesBasicDataId,
                            archivesDataId: v.id
                        }
                    })
                }
                this.$confirm(`确定加入收藏吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.archivespersonalfavoritesSave, list, 'post').then(data => {
                        if (data.status) {
                            this.$message.success('收藏成功')
                            this.search(0)
                        } else {
                            this.$message.error('收藏失败')
                        }
                    })
                })
            },
            // 高级检索
            advancedSearch() {
                // 全部
                if(this.searchForm.archivesBasicDataId == 1) {
                    this.$refs.advancedSearch.init([], this.advancedList, 'recordData')
                } else {
                    this.$refs.advancedSearch.init(this.config, this.advancedList, 'recordData')
                }
            },
            // 获取高级检索条件
            getSrhCondition(data) {
                if (data) {
                    this.advancedList = data.advancedList
                    this.searchForm.whereList = data.whereList
                    this.searchForm.fieldWhereList = data.fieldWhereList
                    this.search(1)
                }
            },
            // 查看
            view(row, method) {
                this.$refs.viewPage.init(row, method, row.archivesBasicDataId, 'database')
            },
            // 获取档案分类
            getClassifyList() {
                this.$axios(this.api.record.selectArchivesBasicData).then((res) => {
                    if (res.status) {
                        this.classifyList = res.data
                        this.classifyList.unshift({
                            archivesBasicName: '全部',
                            id: '1',
                            children: []
                        })
                        this.$nextTick(() => {
                            this.searchForm.keyword = this.$route.query.keyword
                            let id = this.$route.query.archivesBasicDataId
                            this.searchForm.archivesBasicDataId = id
                        })
                    }
                })
            },
            // 导出
            exportData() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let idList = this.dataListSelections.map(item => {
                    return item.id
                })
                this.$refs.exportData.init(idList, this.config, this.searchForm.archivesBasicDataId, '数据库检索')
            },
            // 批量下载
            downloadData() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if (this.dataListSelections.length > 10) {
                    this.$message.warning('最多选择10条数据')
                    return
                }
                const h = this.$createElement;
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, [
                        h('p', {style: 'color: #000000; margin-bottom: 5px'}, '确认下载所选数据？'),
                        h('p', {style: 'color: #999999; font-size: 12px'}, '仅下载有附件的档案数据')
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    let ids = this.dataListSelections.map(item => {
                        return item.id
                    }).join(',')
                    this.$axios(this.api.record.checkDownloadArchivesFileZips, {
                        archivesDataId: ids,
                    }, 'get').then(res => {
                        if(res.status) {
                            this.$axios(this.api.record.downloadArchivesFileZips, {
                                archivesDataId: ids,
                            }, 'get', 'blob').then(res => {
                                let blob = new Blob([res.data], {type: 'application/zip'})
                                let link = document.createElement('a')
                                link.download = decodeURI('下载档案')
                                link.href = window.URL.createObjectURL(blob)
                                link.click()
                                setTimeout(() => {
                                    this.srhListData(0)
                                }, 1000)
                            })
                        } else {
                            this.$message.error(res.msg)
                        }
                    })

                });
            },
            //重置
            reset() {
                this.$refs.multipleTable.clearSelection()
                this.searchForm.whereList = []
                this.searchForm.fieldWhereList = []
                this.advancedList = []
                this.searchForm.archivesBasicDataId = '1'
                this.searchForm.keyword = ''
                this.search(1)
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        },
    }
</script>

<style scoped lang="scss">
    .recordQueryBox {
        .srhBox {
            width: 75%;
            margin: 0;

            .selectBox {
                width: 350px;
            }
        }
    }

    .tips {
        font-size: 13px;
        color: #999999;
        padding: 12px 0;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 15px;
    }

    .tips2 {
        font-size: 13px;
        color: #999999;
        padding-left: 30px;
    }

</style>
